<template>
  <div>
      <CModal :title="$t('checkout.SelectPaymentMethod')"
            :closeOnBackdrop="false"
            :show.sync="paymentShow">
        <CAlert color="info" class="alert-checkout"><span id="price">${{ amountPriceUSD }} USDT</span></CAlert>
        <!--<span class="reference-price">{{ $t('referencePrice') }}: {{ referencePrice }}</span>-->
        <label for="cryptocurrency">{{ $t('checkout.Cryptocurrency') }}</label>
        <ul class="coins">
            <li v-for="coin in coinSupports"
                :key="'coin-' + coin.id"
                v-bind:class="activeStyle(coin.code)"
                @click="selectPayment('cryptocurrency', coin.code)">
            <div class="box"></div>
            <img :src="coin.icon"/>
            <p>{{ coin.name }}</p>
            </li>
        </ul>

        <label for="balance">{{ $t('checkout.AccountBalance') }}: {{ $store.state.balance }} USDT</label>
        <ul class="coins">
            <li key="coin-balance"
                @click="selectPayment('account_balance', 'balance')"
                v-bind:class="activeStyle('balance')"
            >
            <div class="box"></div>
            <img :src="`${publicPath}coins/balance.png`" />
            <p>
                {{ $t('checkout.AccountBalance') }}
            </p>
            </li>
        </ul>

        <template v-slot:footer>
            <button
                type="button"
                class="btn btn-secondary"
                @click="$emit('hide');">
            {{ $t('Cancel') }}
            </button>
            <button
            type="button"
            class="btn btn-primary"
            @click="clickPay()">
            {{ $t('Pay') }}
            </button>
        </template>
    </CModal>
    <payment-crypto :show.sync="showPaymentCrypto"
                    :amount="payment.amount"
                    :address="payment.address"
                    :coin="payment.currency2"/>
  </div>
</template>

<style scoped>
.alert-checkout {
  margin-bottom: 5px;
}
.reference-price {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
</style>

<script>
import axios from "@/plugins/axios.js";
import { payPlan, renewPlan, fetchBalance } from '@/utilities/api'
import Price from '@/utilities/price'
import {get_reference_price} from '@/utilities/price'
import PaymentCrypto from './Crypto.vue'

export default {
  name: "PaymentCheckout",
  components: {
    PaymentCrypto,
  },
  data() {
    return {
      coinSupports: [],
      selectedCoin: "USDT",
      publicPath: process.env.BASE_URL,
      showPaymentCrypto: false,
      referencePrice: Price.RATE_CNY_USDT,
      payment: {
        currency2: null,
        address: null,
        amount: 0
      }
    }
  },
  async created() {
    this.referencePrice = await get_reference_price();
  },
  mounted() {
    this.fetchCoinSupport()
  },
  computed: {
    amountPriceUSD: function () {
      return this.order.amountPrice
      /* if (this.order.type === 'renew' || this.order.type === 'paytrial') { */
        /* return this.order.amountPrice */
      /* } */
      // return (this.order.amountPrice / this.referencePrice ).toFixed(2)
    },
  },
  props: {
    order: Object,
    paymentShow: Boolean,
  },
  watch: {
    paymentShow() {
      this.$emit('update:paymentShow', this.paymentShow)
    },
    showPaymentCrypto() {
      if (!this.showPaymentCrypto) {
        this.payment = {
          currency2: null,
          address: null,
          amount: 0
        }
      }
    }
  },
  methods: {
    activeStyle(coin_code) {
        return {
            active: this.selectedCoin==coin_code
        }
    },
    fetchCoinSupport() {
        axios.get('plan/coin-support/').then(response => {
            this.coinSupports = response.data
        }).catch(error => {
            console.log(error)
        })
    },
    selectPayment(payment, coin="") {
      this.selectedPaymentType = payment
      this.selectedCoin = coin
    },

    clickPay() {
      if (this.selectedPaymentType) {
        if (this.selectedPaymentType === 'cryptocurrency') {
          if (!this.selectedCoin) {
            this.flash(this.$t('checkout.WarningCoin'), 'warning')
            return
          }
        }
        if (this.selectedPaymentType === 'account_balance') {
          if (parseFloat(this.$store.state.balance) < parseFloat(this.$store.state.transaction.checkout.amount_currency1)) {
            this.flash(this.$t('checkout.WarningBalance'), 'warning')
            return
          }
          this.selectedCoin = 'USDT'
        }

        if( this.order.type == "plan" ) {
            payPlan(this.order.plan.id, this.selectedCoin, this.selectedPaymentType, this.order.additional_domain_number).then(response => {
              this.showPaymentChoose = false              
              if (this.selectedPaymentType === 'cryptocurrency') {
                this.payment = response.data
                this.showingPaymentCrypto()
                this.$emit('update:paymentShow', false)
              }
              if (this.selectedPaymentType === 'account_balance') {
                  axios.get('plan/balance/me/').then(response => {
                    this.$store.state.balance = response.data.amount
                    this.$emit('update:paymentShow', false)
                  }).catch(error => console.log(error))
              }
              this.flash(this.$t('checkout.SuccessPaymentType'), 'success', {timeout: 1000})
            }).catch(error => {
              error.forEach(item => {
                this.flash(item, 'warning')
              })
            })
        }
        else if ( this.order.type == "renew" || this.order.type == "paytrial"  ) {
            renewPlan(
                this.order.subscription_id,
                this.selectedCoin,
                this.selectedPaymentType,
            ).then(response => {
                this.selectedCoin = null                
                if (this.selectedPaymentType === 'cryptocurrency') {
                  this.payment = response.data
                  this.showingPaymentCrypto()
                  this.$emit('update:paymentShow', false)
                }
                else if (this.selectedPaymentType === 'account_balance') {
                    axios.get('plan/balance/me/').then(response => {
                      this.$store.state.balance = response.data.amount
                      this.$emit('update:paymentShow', false)
                    }).catch(error => console.log(error))
                    this.$emit('hide');
                }
                this.flash(this.$t('checkout.SuccessPaymentType'), 'success', {timeout: 1000})
            }).catch(error => {
                error.forEach(item => {
                    this.flash(item, 'warning')
                })
            })
        }
        // else if ( this.order.type == "paytrial" ) {
        //   payTrialPlan(
        //     this.order.subscription_id,
        //     this.selectedCoin,
        //     this.selectedPaymentType,
        //   ).then(response => {
        //     this.paymentShow = false
        //     this.pay = false
        //     this.selectedCoin = null
        //     const li = document.querySelectorAll('ul#coins li')
        //     li.forEach(item => {
        //       item.classList.remove('Active')
        //     })
        //     if (this.selectedPaymentType === 'cryptocurrency') {
        //       window.open(response.data.checkout_url, 'Checkout').focus()
        //     }
        //     if (this.selectedPaymentType === 'account_balance') {
        //       axios.get('plan/balance/').then(response => {
        //         this.$store.state.balance = response.data.amount
        //       }).catch(error => console.log(error))
        //     }
        //   }).catch(error => {
        //     error.forEach(item => {
        //       this.flash(item, 'warning')
        //     })
        //   })
        // }
      } else {
        this.flash(this.$t('checkout.WarningPaymentType'), 'warning')
      }
    },
    showingPaymentCrypto() {
      if (this.payment.address) {
        this.showPaymentCrypto = true
      }
    }
  }
};
</script>
<style scoped>
ul.coins {
  padding: 0;
  list-style: none;
}

ul.coins li {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}

ul.coins li.active {
  border: 1px solid #0064bd;
}

ul.coins li div.box {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
}

ul.coins li img {
  width: 100px;
  height: 100px;
}

ul.coins p {
  margin: 0;
  text-align: center;
}
</style>
